import React, { createContext, useState, useEffect } from 'react';
import { getRecipesWithTags } from './connection-notjamieoliver';

// Erstelle den Context
export const RecipesWithTagsContext = createContext();

// Erstelle den Provider
export const RecipesWithTagsProvider = ({ children }) => {
  const [recipesWithTags, setRecipes] = useState([]); // Anfangswert ist ein leeres Array

  useEffect(() => {
    // Rezepte abrufen und setzen
    getRecipesWithTags().then((recipesData) => {
      setRecipes(recipesData || []);  // Füge eine Fallback-Option hinzu
    });
  }, []);

  return (
    <RecipesWithTagsContext.Provider value={{ recipesWithTags }}>
      {children}
    </RecipesWithTagsContext.Provider>
  );
};

import React, { createContext, useState, useContext } from 'react';

// Erstelle den Kontext
const NavContext = createContext();

// Provider für den Kontext, der in der gesamten App verwendet wird
export const NavProvider = ({ children }) => {
  const [activePage, setActivePage] = useState('');

  return (
    <NavContext.Provider value={{ activePage, setActivePage }}>
      {children}
    </NavContext.Provider>
  );
};

// Custom Hook, um den NavContext zu nutzen
export const useNavContext = () => {
  return useContext(NavContext);
};

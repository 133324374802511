import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { RecipesWithTagsContext } from '../helper_components/recipes-with-tags-context';
import { subscribeEmail } from '../helper_components/connection-notjamieoliver';
import CarouselNotjamieoliver from '../helper_components/carousel-notjamieoliver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faPinterest, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { useNavContext } from '../helper_components/navbar-notjamieoliver-context';

const MainPageNotjamieoliver = () => {
  const { recipesWithTags } = useContext(RecipesWithTagsContext);  // Korrekte Destrukturierung
  const [shuffledRecipes, setShuffledRecipes] = useState([]);
  const [popularRecipes, setPopularRecipes] = useState([]);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const [loading, setLoading] = useState(true);
  const { setActivePage } = useNavContext();  // Zugriff auf den Setter für den aktiven Zustand


  useEffect(() => {
    if (recipesWithTags && recipesWithTags.length > 0) {
      shuffleRandomRecipes(recipesWithTags);
      sortRecipesByViews(recipesWithTags);
      setLoading(false);
    }

    updateMetaTags();
  }, [recipesWithTags]);

  useEffect(() => {
    setActivePage('home');  // Setze die aktive Seite auf 'impressum', wenn die Seite geladen wird
  }, [setActivePage]);

  const shuffleRandomRecipes = (recipes) => {
    let shuffled = [...recipes];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    setShuffledRecipes(shuffled);
  };

  const sortRecipesByViews = (recipes) => {
    let sortedRecipes = [...recipes].sort((a, b) => b.views - a.views);
    setPopularRecipes(sortedRecipes);
  };

  const updateMetaTags = () => {
    document.title = 'Willkommen bei Not Jamie Oliver - Entdecke feine Rezepte';
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Entdecke handgelenkmalpi Rezepte für jedermann. Mit vielen Tipps und Tricks und einer guten Rezeptbeschreibung.';
    document.head.appendChild(metaDescription);

    const metaOgTitle = document.createElement('meta');
    metaOgTitle.property = 'og:title';
    metaOgTitle.content = 'Willkommen bei Not Jamie Oliver - Entdecke feine Rezepte';
    document.head.appendChild(metaOgTitle);

    const metaOgDescription = document.createElement('meta');
    metaOgDescription.property = 'og:description';
    metaOgDescription.content = metaDescription.content;
    document.head.appendChild(metaOgDescription);

    const metaOgImage = document.createElement('meta');
    metaOgImage.property = 'og:image';
    metaOgImage.content = 'https://www.notjamieoliver.ch/assets/img/logo-img/logo-hohe-80px-toffol-cooking-blau.png';
    document.head.appendChild(metaOgImage);

    const metaOgUrl = document.createElement('meta');
    metaOgUrl.property = 'og:url';
    metaOgUrl.content = 'https://www.notjamieoliver.ch/main-page-notjamieoliver';
    document.head.appendChild(metaOgUrl);

    const metaOgType = document.createElement('meta');
    metaOgType.property = 'og:type';
    metaOgType.content = 'website';
    document.head.appendChild(metaOgType);
  };

  // Funktion zur Überprüfung, ob die E-Mail gültig ist
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onSubscribe = () => {
    subscribeEmail(email)
      .then((response) => {
        setMessage(response.message || 'E-Mail zur Verifizierung wurde gesendet.');
      })
      .catch((err) => {
        setMessage(err.message || 'Es gab einen Fehler bei der Registrierung.');
      });
  };

  const copyRecipeLink = () => {
    navigator.clipboard.writeText(currentUrl).then(() => {
      alert('Link für Instagram kopiert! Füge ihn manuell in deinen Instagram-Post ein.');
    });
  };

  if (loading) {
    return <div>Daten werden geladen...</div>;
  }

  return (
    <div>
      {/* Beschreibung */}
      <div className="container mt-5">
        <p>
          Die Kunst des Kochens erfordert keine Komplexität. Notjamieoliver Rezepte entfaltet ihre Reize ohne die
          Notwendigkeit von unnötiger Verwicklungen, denn Kochen kann eine elegante Simplizität sein.
        </p>
      </div>

      {/* Social Sharing Buttons */}
      <div className="container social-sharing mt-4">
        <p className="mb-3">Teile diese Seite:</p>
        <a href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`} target="_blank" className="btn btn-outline-primary me-3 mb-3" rel="noreferrer">
          <FontAwesomeIcon icon={faFacebook} /> Facebook
        </a>
        <a href={`https://twitter.com/intent/tweet?text='Willkommen bei Not Jamie Oliver - Entdecke feine Rezepte'&url=${currentUrl}`} target="_blank" className="btn btn-outline-info me-3 mb-3" rel="noreferrer">
          <FontAwesomeIcon icon={faXTwitter} /> (ehemals Twitter)
        </a>
        <a href={`https://pinterest.com/pin/create/button/?url=${currentUrl}&media='https://www.notjamieoliver.ch/assets/img/logo-img/logo-hohe-80px-toffol-cooking-blau.png'`} target="_blank" className="btn btn-outline-danger me-3 mb-3" rel="noreferrer">
          <FontAwesomeIcon icon={faPinterest} /> Pinterest
        </a>
        <a href={`https://api.whatsapp.com/send?text='Willkommen bei Not Jamie Oliver - Entdecke feine Rezepte'%0A${currentUrl}`} target="_blank" className="btn btn-outline-success me-3 mb-3" rel="noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
        </a>
        <button onClick={copyRecipeLink} className="btn btn-outline-light me-3 mb-3">
          <FontAwesomeIcon icon={faInstagram} /> Instagram
        </button>
      </div>

      {/* Carousel */}
      <CarouselNotjamieoliver />

      {/* Blog Bereich */}
      <section className="blog_area pt-4">
        <div className="container">
          <div className="row justify-content-center">
            {/* Blog Main */}
            <div className="col-12 col-lg-8">
              <div className="row">
                {shuffledRecipes.length > 0 && (
                  <div className="col-12">
                    <div className="single-post wow fadeInUp">
                      <div className="post-thumb">
                        <Link to={`/recipe-detail-page-notjamieoliver/${shuffledRecipes[0].id}`}>
                          <img src={`https://images.notjamieoliver.ch/assets/img/recipe-img/${shuffledRecipes[0].id}/${shuffledRecipes[0].id}_lg.jpg`} alt={shuffledRecipes[0].name} />
                        </Link>
                      </div>
                      <div className="post-content">
                        <div className="post-meta d-flex justify-content-between">
                          <div className="post-author-date-area d-flex mt-3">
                            {/* Post Author */}
                            <div className="post-author me-2">
                              Von {shuffledRecipes[0].author} | &nbsp;
                            </div>
                            {/* Post Date */}
                            <div className="post-date">
                              {shuffledRecipes[0].publishing_date_str}
                            </div>
                          </div>
                          <div className="post-view ms-auto d-flex align-items-center mt-3">
                            {/* Post View */}
                            <p className="mb-0">{shuffledRecipes[0].views}&nbsp;
                              <FontAwesomeIcon icon={faEye} />
                            </p>
                          </div>
                        </div>

                        <h2 className="post-headline">{shuffledRecipes[0].name}</h2>
                        <p>{shuffledRecipes[0].description}</p>

                        <div className="d-flex justify-content-between align-items-end">
                          <div>
                            {shuffledRecipes[0].tags.map((tag, index) => (
                              <span key={index} className="badge text-primary border-primary-subtle border bg-primary-subtle me-1">
                                <Link to={`/recipes-by-tag-page-notjamieoliver/${tag}`}>{tag}</Link>
                              </span>
                            ))}
                          </div>
                          <Link to={`/recipe-detail-page-notjamieoliver/${shuffledRecipes[0].id}`}>
                            <button className="btn btn-outline-primary">Weiter...</button>
                          </Link>
                        </div>
                      </div>

                    </div>
                  </div>
                )}

                <div className="mt-5 mb-5 row">
                  {shuffledRecipes.slice(1, 5).map((recipe, i) => (
                    <div key={i} className="col-12 col-md-6">
                      <div className="single-post wow fadeInUp">
                        <div className="post-thumb">
                          <Link to={`/recipe-detail-page-notjamieoliver/${recipe.id}`}>
                            <img src={`https://images.notjamieoliver.ch/assets/img/recipe-img/${recipe.id}/${recipe.id}_md.jpg`} alt={recipe.name} />
                          </Link>
                        </div>
                        <div className="post-content">
                        <div className="post-meta d-flex justify-content-between">
                          <div className="post-author-date-area d-flex mt-3">
                            {/* Post Author */}
                            <div className="post-author me-2">
                              Von {shuffledRecipes[0].author} | &nbsp;
                            </div>
                            {/* Post Date */}
                            <div className="post-date">
                              {shuffledRecipes[0].publishing_date_str}
                            </div>
                          </div>
                          </div>
                          <h4 className="post-headline">{recipe.name}</h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="col-12">
                  {shuffledRecipes.slice(6, 10).map((recipe, i) => (
                    <div key={i} className="list-blog single-post d-sm-flex wow fadeInUpBig">
                      <div className="post-thumb flex-shrink-0 col-lg-6 col-md-6 col-sm-12">
                        <Link to={`/recipe-detail-page-notjamieoliver/${recipe.id}`}>
                          <img src={`https://images.notjamieoliver.ch/assets/img/recipe-img/${recipe.id}/${recipe.id}_md.jpg`} alt={recipe.name} />
                        </Link>
                      </div>
                      <div className="post-content col-lg-6 col-md-6 col-sm-12">
                      <div className="post-meta d-flex justify-content-between">
                          <div className="post-author-date-area d-flex mt-3">
                            {/* Post Author */}
                            <div className="post-author me-2">
                              Von {recipe.author} | &nbsp;
                            </div>
                            {/* Post Date */}
                            <div className="post-date">
                              {recipe.publishing_date_str}
                            </div>
                          </div>
                          <div className="post-view ms-auto d-flex align-items-center mt-3">
                            {/* Post View */}
                            <p className="mb-0">{recipe.views}&nbsp;
                              <FontAwesomeIcon icon={faEye} />
                            </p>
                          </div>
                        </div>
                        <h4 className="post-headline">{recipe.name}</h4>
                        <p>{recipe.description}</p>
                        <div className="mt-3">
                          {recipe.tags.map((tag, index) => (
                            <span key={index} className="badge text-primary border-primary-subtle border bg-primary-subtle me-1">
                              <Link to={`/recipes-by-tag-page-notjamieoliver/${tag}`}>{tag}</Link>
                            </span>
                          ))}
                        </div>
                        <Link to="">
                          <button className="btn btn-outline-primary mt-3">Weiter...</button>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Blog Sidebar */}
            <div className="col-12 col-lg-4">
              <div className="blog-sidebar">
                <div className="single-widget-area about-me-widget text-center">
                  <div className="widget-title">
                    <h6>Über mich...</h6>
                  </div>
                  <Link to="/about-page-notjamieoliver">
                    <div className="about-me-widget-thumb">
                      <img src="../../assets/img/about-img/Start_Portrait_Oliver.png" alt="Über mich" />
                    </div>
                  </Link>
                  <h4>Hi, ich bin Oliver, nicht Jamie Oliver</h4>
                  <p>
                    Aber inspiriert durch diese Person, jedoch nicht nur. Ich brenne dafür, neue Rezepte zu kreieren
                    und daraus meinen eigenen Kochstil zu entwickeln.
                  </p>
                </div>

                <div className="single-widget-area popular-post-widget">
                  <div className="widget-title">
                    <h6>Top 5</h6>
                  </div>
                  {popularRecipes.slice(0, 5).map((recipe, index) => (
                    <div key={index} className="single-popular-post d-flex align-items-start">
                      <div className="post-image me-2">
                        <Link to={`/recipe-detail-page-notjamieoliver/${recipe.id}`}>
                          <img src={`https://images.notjamieoliver.ch/assets/img/recipe-img/${recipe.id}/${recipe.id}_sm.jpg`} alt={recipe.name} />
                        </Link>
                      </div>
                      <div className="post-content">
                        <Link to={`/recipe-detail-page-notjamieoliver/${recipe.id}`}>{recipe.name}</Link>
                        <p className="post-date">{recipe.publishing_date_str}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="single-widget-area">
                  <div className="form-group">
                    <div className="widget-title">
                      <h6>Newsletter</h6>
                    </div>
                    <input
                      className="form-control"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Gib Deine E-Mail-Adresse ein"
                      required
                    />
                    <button className="btn btn-outline-primary mt-3 me-3">
                      <Link to="/unsubscribe-email-page-notjamieoliver">Abmelden</Link>
                    </button>
                    <button className="btn btn-outline-primary mt-3" 
                      onClick={onSubscribe}
                      disabled={!isValidEmail(email)}>
                      Abonnieren
                    </button>
                    {message && <p>{message}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainPageNotjamieoliver;

import React, { useEffect } from 'react';
import { useNavContext } from '../helper_components/navbar-notjamieoliver-context';

const DataProtectionPageNotjamieoliver = () => {
  const { setActivePage } = useNavContext();  // Zugriff auf den Setter für den aktiven Zustand

  useEffect(() => {
    setActivePage('dataprotection');  // Setze die aktive Seite auf 'dataprotection', wenn die Seite geladen wird
  }, [setActivePage]);

  return (
    <div className="container mt-5 mb-5">
      <h1 className="mb-4">Datenschutzerklärung</h1>

      <h2>1. Allgemeine Hinweise</h2>
      <p>
        Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
      </p>
      <p>
        Diese Webseite kann in der Regel ohne die Angabe personenbezogener Daten besucht werden. Soweit auf unseren Seiten
        personenbezogene Daten erhoben werden (z.B. bei der Anmeldung zu unserem Newsletter), erfolgt dies stets auf freiwilliger
        Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
      </p>

      <h2 className="mt-3">2. Erfassung von Daten</h2>
      <p>
        Bei der Nutzung unserer Webseite erfasst unser System automatisch allgemeine technische Daten, wie z.B. IP-Adressen,
        Browserinformationen und Zugriffszeiten. Diese Daten werden nur zur Verbesserung der Benutzerfreundlichkeit und der Sicherheit
        der Webseite verwendet und ermöglichen keine Rückschlüsse auf einzelne Personen.
      </p>

      <h2 className="mt-3">3. Newsletter</h2>
      <p>
        Wenn Sie sich für unseren Newsletter anmelden, speichern wir Ihre E-Mail-Adresse. Diese wird ausschließlich zum Versand unseres
        Newsletters verwendet und nicht an Dritte weitergegeben.
      </p>
      <p className="mt-3">
        Sie können sich jederzeit vom Newsletter abmelden, indem Sie den Abmeldelink in einer der Newsletter-E-Mails nutzen oder uns direkt
        kontaktieren. (<a href="mailto:info@notjamieoliver.ch">Kontaktieren Sie uns per E-Mail</a>). Ihre E-Mail-Adresse wird daraufhin aus unserem Verteiler gelöscht.
      </p>

      <h2 className="mt-3">4. Speicherung von Daten</h2>
      <p>
        Die von uns erfassten Daten werden sicher gespeichert und vor unbefugtem Zugriff geschützt. Die Aufbewahrung erfolgt nur so lange,
        wie es für die Erfüllung der beschriebenen Zwecke notwendig ist oder gesetzliche Vorschriften dies erfordern.
      </p>

      <h2 className="mt-3">5. Ihre Rechte</h2>
      <p>
        Sie haben jederzeit das Recht auf Auskunft über die von uns gespeicherten personenbezogenen Daten sowie auf Berichtigung, Löschung
        oder Einschränkung der Verarbeitung. Kontaktieren Sie uns dazu bitte über die im Impressum angegebenen Kontaktdaten.
      </p>
    </div>
  );
};

export default DataProtectionPageNotjamieoliver;

// connection-notjamieoliver.js

import axios from 'axios';

// Basis-URL deiner API aus Umgebungsvariablen oder direkt angeben
const api_Url = process.env.REACT_APP_API_URL || 'https://deine-api-url.com';
const api_Url_Recipes = process.env.REACT_APP_API_URL_RECIPES || 'https://deine-api-url.com';

// Standard-HTTP-Header
const httpOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// Funktion zum Suchen von Rezepten
export async function searchRecipes(query) {
  try {
    const response = await axios.get(`${api_Url_Recipes}/search`, {
      params: { query },
    });
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Suchergebnisse:', error);
    return [];
  }
}

// Funktion zum Abrufen aller Tags mit Rezeptstatus
export async function getAllTagsWithRecipeStatus(recipe_id) {
  try {
    const response = await axios.get(`${api_Url_Recipes}/recipe_tags/${recipe_id}`);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Tags:', error);
    return [];
  }
}

// Funktion zum Hinzufügen eines neuen Tags
export async function addTag(name) {
  try {
    const response = await axios.post(
      `${api_Url_Recipes}/recipe_tags/new`,
      { name },
      httpOptions
    );
    return response.data;
  } catch (error) {
    console.error('Fehler beim Hinzufügen eines neuen Tags:', error);
    return null;
  }
}

// Funktion zum Löschen eines Tags
export async function deleteTag(id) {
  try {
    const response = await axios.delete(`${api_Url_Recipes}/recipe_tags/delete/${id}`);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Löschen des Tags:', error);
    return null;
  }
}

// Funktion zum Aktualisieren der Rezept-Tags
export async function updateRecipeTags(recipe_id, tags) {
  try {
    const response = await axios.post(
      `${api_Url_Recipes}/recipe_tags/${recipe_id}`,
      { tags },
      httpOptions
    );
    return response.data;
  } catch (error) {
    console.error('Fehler beim Aktualisieren der Rezept-Tags:', error);
    return null;
  }
}

// Funktion zum Abrufen eines Rezepts nach ID
export async function getRecipeById(id) {
  try {
    const response = await axios.get(`${api_Url_Recipes}/${id}`);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen des Rezepts:', error);
    return null;
  }
}

// Funktion zum Abrufen aller Rezepte
export async function getRecipes() {
  try {
    const response = await axios.get(`${api_Url_Recipes}`);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Rezepte:', error);
    return [];
  }
}

// Funktion zum Abrufen von Rezepten mit Tags
export async function getRecipesWithTags() {
  try {
    const response = await axios.get(`${api_Url_Recipes}/recipes-with-tags`);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Rezepte mit Tags:', error);
    return [];
  }
}

// Funktion zum Abrufen von Rezepten nach Tag-Name
export async function getRecipesWithTagsByTag(tag_name) {
  try {
    const response = await axios.get(`${api_Url_Recipes}/recipes-with-tags/byTag/${tag_name}`);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Rezepte nach Tag:', error);
    return [];
  }
}

// Funktion zum Abrufen der Rezept-Zutaten-Gruppen nach Rezept-ID
export async function getRecipeIngredientGroupsById(id) {
  try {
    const response = await axios.get(`${api_Url_Recipes}/${id}/recipe_ingredient_groups`);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Zutaten-Gruppen:', error);
    return [];
  }
}

// Funktion zum Abrufen der Zutaten nach Gruppen-ID
export async function getRecipeIngredientsByGroupId(group_id) {
  try {
    const response = await axios.get(`${api_Url_Recipes}/${group_id}/recipe_ingredients_by_group_id`);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Zutaten nach Gruppen-ID:', error);
    return [];
  }
}

// Funktion zum Abrufen der Schritte nach Gruppen-ID
export async function getRecipeStepsByGroupId(group_id) {
  try {
    const response = await axios.get(`${api_Url_Recipes}/${group_id}/recipe_steps_by_group_id`);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Schritte nach Gruppen-ID:', error);
    return [];
  }
}

// Funktion zum Abrufen der Schritt-Gruppen nach Rezept-ID
export async function getRecipeStepGroupsById(id) {
  try {
    const response = await axios.get(`${api_Url_Recipes}/${id}/recipe_step_groups`);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Schritt-Gruppen:', error);
    return [];
  }
}

// Funktion zum Abrufen der Schritte nach Rezept-ID
export async function getStepsByRecipeId(recipe_id) {
  try {
    const response = await axios.get(`${api_Url_Recipes}/${recipe_id}/recipe_steps`);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Schritte nach Rezept-ID:', error);
    return [];
  }
}

// Funktion zum Abrufen von Tipps nach Rezept-ID
export async function getTipsByRecipeId(recipe_id) {
  try {
    const response = await axios.get(`${api_Url_Recipes}/${recipe_id}/tips`);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Tipps nach Rezept-ID:', error);
    return [];
  }
}

// Funktion zum Löschen eines Schritts
export async function deleteStep(step_id) {
  try {
    const response = await axios.delete(`${api_Url_Recipes}/step/${step_id}`);
    return response.data;
  } catch (error) {
    console.error(`Fehler beim Löschen des Schritts mit ID ${step_id}:`, error);
    return null;
  }
}

// Funktion zum Erstellen eines neuen Rezepts
export async function createRecipe(recipeData) {
  try {
    const response = await axios.post(`${api_Url_Recipes}/new`, recipeData, httpOptions);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Erstellen des Rezepts:', error);
    return null;
  }
}

// Funktion zum Bearbeiten eines Rezepts
export async function editRecipe(id, recipeData) {
  try {
    const response = await axios.post(`${api_Url_Recipes}/update/${id}`, recipeData, httpOptions);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Bearbeiten des Rezepts:', error);
    return null;
  }
}

// Funktion zum Löschen eines Rezepts
export async function deleteRecipe(id) {
  try {
    const response = await axios.delete(`${api_Url_Recipes}/${id}`);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Löschen des Rezepts:', error);
    return null;
  }
}

// Funktion zum Speichern der Zutaten-Gruppen eines Rezepts
export async function saveRecipeIngredientGroups(recipe_id, recipeIngredientGroups) {
  try {
    const response = await axios.post(
      `${api_Url_Recipes}/${recipe_id}/recipe_ingredient_groups`,
      recipeIngredientGroups,
      httpOptions
    );
    return response.data;
  } catch (error) {
    console.error('Fehler beim Speichern der Zutaten-Gruppen:', error);
    return null;
  }
}

// Funktion zum Speichern der Schritt-Gruppen eines Rezepts
export async function saveRecipeStepGroups(recipe_id, recipeStepGroups) {
  try {
    const response = await axios.post(
      `${api_Url_Recipes}/${recipe_id}/recipe_step_groups`,
      recipeStepGroups,
      httpOptions
    );
    return response.data;
  } catch (error) {
    console.error('Fehler beim Speichern der Schritt-Gruppen:', error);
    return null;
  }
}

// Funktion zum Aktualisieren des Namens einer Zutaten-Gruppe
export async function updateRecipeIngredientGroupName(group_id, group_name) {
  try {
    const response = await axios.post(
      `${api_Url_Recipes}/update/${group_id}/recipe_ingredient_groups`,
      { group_name },
      httpOptions
    );
    return response.data;
  } catch (error) {
    console.error('Fehler beim Aktualisieren des Namens der Zutaten-Gruppe:', error);
    return null;
  }
}

// Funktion zum Aktualisieren des Namens einer Schritt-Gruppe
export async function updateRecipeStepGroupName(group_id, group_name) {
  try {
    const response = await axios.post(
      `${api_Url_Recipes}/update/${group_id}/recipe_step_groups`,
      { group_name },
      httpOptions
    );
    return response.data;
  } catch (error) {
    console.error('Fehler beim Aktualisieren des Namens der Schritt-Gruppe:', error);
    return null;
  }
}

// Funktion zum Löschen einer Zutaten-Gruppe
export async function deleteIngredientGroup(groupId) {
  try {
    const response = await axios.delete(`${api_Url_Recipes}/recipe_ingredient_groups/${groupId}`);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Löschen der Zutaten-Gruppe:', error);
    return null;
  }
}

// Funktion zum Löschen einer Schritt-Gruppe
export async function deleteStepGroup(groupId) {
  try {
    const response = await axios.delete(`${api_Url_Recipes}/recipe_step_groups/${groupId}`);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Löschen der Schritt-Gruppe:', error);
    return null;
  }
}

// Funktion zum Löschen von Zutaten nach Gruppen-ID und Zutaten-ID
export async function deleteRecipeIngredientsByGroupId(group_id, ingredient_id) {
  try {
    const response = await axios.delete(
      `${api_Url_Recipes}/${group_id}/recipe_ingredients_by_group_id/${ingredient_id}`,
      httpOptions
    );
    return response.data;
  } catch (error) {
    console.error('Fehler beim Löschen der Zutaten nach Gruppen-ID:', error);
    return null;
  }
}

// Funktion zum Löschen von Schritten nach Gruppen-ID und Schritt-ID
export async function deleteRecipeStepsByGroupId(group_id, step_id) {
  try {
    const response = await axios.delete(
      `${api_Url_Recipes}/${group_id}/recipe_steps_by_group_id/${step_id}`,
      httpOptions
    );
    return response.data;
  } catch (error) {
    console.error('Fehler beim Löschen der Schritte nach Gruppen-ID:', error);
    return null;
  }
}

// Funktion zum Speichern der Zutaten nach Gruppen-ID
export async function saveRecipeIngredientsByGroupId(group_id, recipeIngredientsByGroupId) {
  try {
    const response = await axios.post(
      `${api_Url_Recipes}/${group_id}/recipe_ingredients_by_group_id`,
      recipeIngredientsByGroupId,
      httpOptions
    );
    return response.data;
  } catch (error) {
    console.error('Fehler beim Speichern der Zutaten nach Gruppen-ID:', error);
    return null;
  }
}

// Funktion zum Speichern der Schritte nach Gruppen-ID
export async function saveRecipeStepsByGroupId(group_id, recipeStepsByGroupId) {
  try {
    const response = await axios.post(
      `${api_Url_Recipes}/${group_id}/recipe_steps_by_group_id`,
      recipeStepsByGroupId,
      httpOptions
    );
    return response.data;
  } catch (error) {
    console.error('Fehler beim Speichern der Schritte nach Gruppen-ID:', error);
    return null;
  }
}

// Funktion zum Speichern der Schritte eines Rezepts
export async function saveRecipeSteps(recipe_id, recipeSteps) {
  try {
    const response = await axios.post(
      `${api_Url_Recipes}/${recipe_id}/recipe_steps`,
      recipeSteps,
      httpOptions
    );
    return response.data;
  } catch (error) {
    console.error('Fehler beim Speichern der Schritte nach Rezept-ID:', error);
    return null;
  }
}

// Funktion zum Hinzufügen eines Tipps zu einer Schritt-ID
export async function addTipToStepId(step_id, description) {
  try {
    const response = await axios.post(
      `${api_Url_Recipes}/${step_id}/tips`,
      { description },
      httpOptions
    );
    return response.data;
  } catch (error) {
    console.error('Fehler beim Hinzufügen eines Tipps zur Schritt-ID:', error);
    return null;
  }
}

// Funktion zum Hinzufügen einer Ansicht zu einem Rezept
export async function addViewToRecipe(id) {
  try {
    const response = await axios.post(
      `${api_Url_Recipes}/${id}/add-view`,
      {},
      httpOptions
    );
    return response.data;
  } catch (error) {
    console.error('Fehler beim Hinzufügen einer Ansicht zum Rezept:', error);
    return null;
  }
}

// Funktion zum Abonnieren des Newsletters
export async function subscribeEmail(email) {
  try {
    const response = await axios.post(`${api_Url}/newsletter/subscribe`, { email }, httpOptions);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abonnieren des Newsletters:', error);
    return null;
  }
}

// Funktion zur Verifizierung der E-Mail-Adresse
export async function verifyEmail(token) {
  try {
    const response = await axios.get(`${api_Url}/verify-email`, {
      params: { token },
    });
    return response.data;
  } catch (error) {
    console.error('Fehler bei der E-Mail-Verifizierung:', error);
    return null;
  }
}

// Funktion zum Abbestellen des Newsletters
export async function unsubscribeNewsletter(email) {
  try {
    const response = await axios.post(`${api_Url}/newsletter/unsubscribe`, { email }, httpOptions);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abbestellen des Newsletters:', error);
    return null;
  }
}

import React from 'react';
import { Link } from 'react-router-dom';

const FooterNotjamieoliver = () => {
  return (
    <footer className="footer_area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer-content">
              {/* Logo Area Start */}
              <div className="footer-logo-area text-center">
                <Link className="navbar-brand" to="/main-page-notjamieoliver">
                  <img
                    src="../../assets/img/logo-img/logo-hohe-80px-toffol-cooking-blau.png"
                    id="navbar-logo"
                    alt="Logo"
                    className="d-inline-block align-text-center"
                  />
                </Link>
              </div>
              {/* Menu Area Start */}
              <nav className="navbar navbar-expand-sm">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#notjamieoliverfood-footer-nav"
                  aria-controls="notjamieoliverfood-footer-nav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="fa fa-bars" aria-hidden="true"></i> Menu
                </button>
                {/* Menu Area Start */}
                <div
                  className="navbar-collapse collapse d-flex justify-content-center"
                  id="notjamieoliverfood-footer-nav"
                >
                  <ul className="navbar-nav text-center">
                    <li className="nav-item active">
                      <Link className="nav-link" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/recipes-all-page-notjameioliver">
                        Rezepte
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/data-protection-page-notjamieoliver">
                        Datenschutz
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/impressum-page-notjamieoliver">
                        Impressum
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/about-page-notjamieoliver">
                        Über
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* Copywrite Text */}
            <div className="copy_right_text text-center">
              <p>Copyright 2024 | All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterNotjamieoliver;

import React, { useState } from 'react';
import { unsubscribeNewsletter } from '../helper_components/connection-notjamieoliver';

const NewsletterUnsubscribeNotjamieoliver = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailValid, setEmailValid] = useState(true);

  const validateEmail = (email) => {
    // Einfache E-Mail-Validierung
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onUnsubscribe = async (e) => {
    e.preventDefault();
    
    // Überprüfe, ob die E-Mail gültig ist
    if (!validateEmail(email)) {
      setEmailValid(false);
      return;
    } else {
      setEmailValid(true);
    }

    try {
      const response = await unsubscribeNewsletter(email);
      setMessage(response.message);
    } catch (err) {
      setMessage('Es gab einen Fehler bei der Abmeldung. Bitte versuchen Sie es erneut.');
    }
  };

  return (
    <section className="container d-flex justify-content-center align-items-center vh-100">
      <div className="section_padding_100 unsubscribe-container">
        <div className="unsubscribe-container container">
          <form onSubmit={onUnsubscribe}>
            <div>
              <label htmlFor="email">E-Mail-Adresse</label>
              <input 
                type="email" 
                className="form-control" 
                placeholder="Ihre E-Mail-Adresse"
                value={email}
                onChange={(e) => setEmail(e.target.value)} 
              />
              {!emailValid && (
                <small className="text-danger">Bitte geben Sie eine gültige E-Mail-Adresse ein.</small>
              )}
            </div>

            <button type="submit" className="btn btn-outline-primary mt-3" disabled={!email}>
              Abmelden
            </button>
          </form>

          {message && <p>{message}</p>}
        </div>
      </div>
    </section>
  );
};

export default NewsletterUnsubscribeNotjamieoliver;

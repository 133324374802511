import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { jsPDF } from 'jspdf';
import {
  getRecipeById,
  getRecipeIngredientGroupsById,
  getRecipeIngredientsByGroupId,
  getRecipeStepGroupsById,
  getRecipeStepsByGroupId,
  getTipsByRecipeId,
  addViewToRecipe,
} from '../helper_components/connection-notjamieoliver';
import { useNavContext } from '../helper_components/navbar-notjamieoliver-context';
import CarouselNotjamieoliver from '../helper_components/carousel-notjamieoliver';



const RecipeDetailPageNotjamieoliver = () => {
  const { id } = useParams(); // ID aus der URL
  const [recipe, setRecipe] = useState(null);
  const [ingredientGroups, setIngredientGroups] = useState([]);
  const [ingredientsByGroup, setIngredientsByGroup] = useState({});
  const [recipeStepGroups, setRecipeStepGroups] = useState([]);
  const [stepsByGroup, setStepsByGroup] = useState({});
  const [tipsByStepId, setTipsByStepId] = useState({});
  const [visibleTips, setVisibleTips] = useState({});
  const { setActivePage } = useNavContext();  // Zugriff auf den NavContext

  useEffect(() => {
    setActivePage('recipe-detail');  // Setzt den aktiven Menüpunkt
  }, [setActivePage]);

  useEffect(() => {
    if (id) {
      loadRecipeDetails(id);
      addViewToRecipe(id).catch(err => console.error('Error updating views:', err));
    }
  }, [id]);

  const loadRecipeDetails = async (recipeID) => {
    try {
      const recipeData = await getRecipeById(recipeID);
      setRecipe(recipeData);

      const ingredientGroupsData = await getRecipeIngredientGroupsById(recipeID);
      setIngredientGroups(ingredientGroupsData);

      const recipeStepGroupsData = await getRecipeStepGroupsById(recipeID);
      setRecipeStepGroups(recipeStepGroupsData);

      await loadRecipeIngredientsByGroup(ingredientGroupsData);
      await loadStepsAndTips(recipeStepGroupsData, recipeID);
    } catch (err) {
      console.error('Error loading recipe:', err);
    }
  };

  const loadRecipeIngredientsByGroup = async (ingredientGroups) => {
    try {
      const ingredients = {};
      for (const group of ingredientGroups) {
        ingredients[group.group_id] = await getRecipeIngredientsByGroupId(group.group_id);
      }
      setIngredientsByGroup(ingredients);
    } catch (err) {
      console.error('Error loading ingredients:', err);
    }
  };

  const loadStepsAndTips = async (stepGroups, recipeID) => {
    try {
      const steps = {};
      for (const group of stepGroups) {
        steps[group.group_id] = await getRecipeStepsByGroupId(group.group_id);
      }
      setStepsByGroup(steps);

      const tips = await getTipsByRecipeId(recipeID);
      const tipsByStep = {};
      tips.forEach((tip) => {
        if (!tipsByStep[tip.step_id]) {
          tipsByStep[tip.step_id] = [];
        }
        tipsByStep[tip.step_id].push(tip);
      });
      setTipsByStepId(tipsByStep);
    } catch (err) {
      console.error('Error loading steps or tips:', err);
    }
  };

  const toggleTipVisibility = (stepId) => {
    setVisibleTips((prev) => ({ ...prev, [stepId]: !prev[stepId] }));
  };

  const isTipVisible = (stepId) => visibleTips[stepId] || false;

  const getTipsForStep = (stepId) => tipsByStepId[stepId] || [];

  const updateMetaTags = () => {
    if (recipe) {
      const description = recipe.description || 'Ein köstliches Rezept auf notjamieoliver.ch';
      const imageUrl = `https://notjamieoliver.ch/assets/img/recipe-img/${recipe.id}/${recipe.id}_th.jpg`;

      return (
        <Helmet>
          <title>{`${recipe.name} - notJamieOliver`}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={recipe.name} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={imageUrl} />
          <meta property="og:image:url" content={imageUrl} />
          <meta property="og:image:secure_url" content={imageUrl} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:alt" content={recipe.name} />
          <meta property="og:image:height" content="350" />
          <meta property="og:image:width" content="350" />
          <meta property="og:url" content={`https://notjamieoliver.ch/detail-page-notjamieoliver/${recipe.id}`} />
          <meta property="og:type" content="website" />
        </Helmet>
      );
    }
    return null;
  };

function parseStepTextForPDF(stepText) {
    const linkRegex = /<a href="(.*?)">(.*?)<\/a>/g;
    const parsedContent = [];
    let lastIndex = 0;
    let match;
  
    // Suche nach Links im Text und trenne ihn auf
    while ((match = linkRegex.exec(stepText)) !== null) {
      const beforeLinkText = stepText.substring(lastIndex, match.index);
      const linkURL = match[1];
      const linkText = match[2];
  
      // Text vor dem Link hinzufügen
      if (beforeLinkText) {
        parsedContent.push({ type: 'text', content: beforeLinkText });
      }
  
      // Link hinzufügen
      parsedContent.push({ type: 'link', content: linkText, url: linkURL });
  
      lastIndex = match.index + match[0].length;
    }
  
    // Text nach dem letzten Link hinzufügen
    if (lastIndex < stepText.length) {
      parsedContent.push({ type: 'text', content: stepText.substring(lastIndex) });
    }
  
    return parsedContent;
  };
  
// Die Funktion zum Erstellen des PDF-Dokuments
const downloadRecipeAsPDF = () => {
  const doc = new jsPDF();

  // Setze Ränder
  let margin = 10;
  const maxLineWidth = 190 - 2 * margin; // Maximale Breite für Text

  // Optional: Rezeptbild (wenn vorhanden)
  const imageURL = `https://images.notjamieoliver.ch/assets/img/recipe-img/${recipe.id}/${recipe.id}_lg.jpg`;
  doc.addImage(imageURL, 'PNG', margin, 15, 86, 50); // Position des Bildes (x, y, Breite, Höhe)

  // Rezeptname als Header
  doc.setFontSize(22);
  doc.setFont('helvetica', 'bold');
  const recipeLine = doc.splitTextToSize(recipe.name, maxLineWidth); // Zeilenumbruch

  let yOffset = 75; // Startposition

  // Rezeptname hinzufügen
  recipeLine.forEach((line) => {
    doc.text(line, margin, yOffset);
    yOffset += 10;
  });

  // Trennlinie nach dem Titel
  doc.setLineWidth(0.5);
  doc.line(margin, yOffset, 200 - margin, yOffset);
  yOffset += 10;

  // Abschnitt: Zutaten
  doc.setFontSize(16);
  doc.setFont('helvetica', 'bold');
  doc.text('Zutaten', margin, yOffset);
  yOffset += 10;

  // Zutaten Gruppen und Einzelzutaten durchlaufen
  ingredientGroups.forEach((group) => {
    if (yOffset > 270) { // Seitenumbruch, wenn der Platz auf der Seite knapp wird
      doc.addPage();
      yOffset = 20;
    }

    // Zutaten-Gruppe hinzufügen
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    if (group.group_name) {
      doc.text(group.group_name, margin, yOffset);
      yOffset += 10;
    }

    // Berechne die längste Quantity, um die Einrückung anzupassen
    const quantityLength = Math.max(...(ingredientsByGroup[group.group_id]?.map(i => i.quantity?.length || 0)));

    ingredientsByGroup[group.group_id].forEach((ingredient) => {
      if (yOffset > 270) {
        doc.addPage();
        yOffset = 20;
      }

      // Einzelzutat hinzufügen
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      doc.text(`${ingredient.quantity || ''}`, margin, yOffset);
      doc.text(`${ingredient.ingredient}`, margin + (quantityLength * 4 + 2), yOffset);
      yOffset += 6;
    });
  });

  // Abschnitt: Zubereitungsschritte
  doc.setFontSize(16);
  doc.setFont('helvetica', 'bold');
  yOffset += 10;
  doc.text('Zubereitung', margin, yOffset);
  // Change Margin a little bit:
  margin += 4;
  yOffset += 10;

  // Zubereitungsschritte durchlaufen
  recipeStepGroups.forEach((group) => {
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    if (group.group_name) {
      doc.text(group.group_name, margin, yOffset);
      yOffset += 10;
    }

    stepsByGroup[group.group_id].forEach((step, index) => {
      if (yOffset > 270) {
        doc.addPage();
        yOffset = 20;
      }

      const stepNumber = `${index + 1}`;
      const parsedStepText = parseStepTextForPDF(step.description);

      // Setze Zubereitungsschritte und ggf. Links
      let multipleSteps = 0;
      let singleSteps = 1;
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      if (stepsByGroup[group.group_id].length > 1) {
        doc.text(stepNumber, margin, yOffset);
        doc.circle(margin + 1, yOffset - 1, 4);
        multipleSteps = 1;
        singleSteps = 0;
      }

      let lastElementLength = 0;

      parsedStepText.forEach((element) => {
        if (element.type === 'text') {
          const stepLines = doc.splitTextToSize(element.content, maxLineWidth);
          stepLines.forEach((line) => {
            doc.text(line, margin - (singleSteps*4) + (multipleSteps*10), yOffset);
            yOffset += 5;
            lastElementLength = line.length;
            console.log('lastElementLength = ', lastElementLength);
          });
        } else if (element.type === 'link') {
            yOffset -= 5;
            doc.setTextColor(0, 0, 255);
            console.log('lastElementLength = ', lastElementLength);
            doc.textWithLink(element.content, lastElementLength * 2 + margin - (singleSteps*4) + (multipleSteps*10), yOffset, { url: element.url });
            // Setze die Textfarbe zurück auf Schwarz
            doc.setTextColor(0, 0, 0); // RGB für Schwarz
            yOffset += 5;
        }
      });
      yOffset += 5;
    });
  });

  // Fußzeile (optional: Website-Link oder Logo)
  doc.setFontSize(10);
  doc.setFont('helvetica', 'italic');
  doc.text('www.notjamieoliver.ch', margin, 290); // Fußzeile

  // PDF speichern und herunterladen
  doc.save(`${recipe.name}.pdf`);
};

  if (!recipe) {
    return <div>Daten werden geladen...</div>;
  }

  return (
    <div className="container">
        {updateMetaTags()}

      {/* Breadcumb Area */}
      <div className="breadcumb-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2"></div>
            <div className="col-lg-10">
              <div className="d-flex flex-column bradcumb-title mt-5">
                <h1>{recipe.name}</h1>
                <p>{recipe.category} / {recipe.publishing_date_str}</p>
                <div className="post-view">
                  <p>{recipe.views}<i className="ms-3 fa-regular fa-eye"></i></p>
                </div>
                <div className="img-container">
                  <img src={`https://images.notjamieoliver.ch/assets/img/recipe-img/${recipe.id}/${recipe.id}_lg.jpg`} alt={recipe.name} className="img-fluid" />
                </div>
                <div className="single-post mt-3">
                  {recipe.tags.map((tag, index) => (
                    <span key={index} className="badge text-primary border-primary-subtle border bg-primary-subtle me-1">
                    <Link to={`/recipes-by-tag-page-notjamieoliver/${tag}`}>{tag}</Link>
                  </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Detail Page */}
      <section className="container detail-page mt-2">
        <div className="row detail-page">
          <div className="col-lg-3"></div>
          <div className="col-lg-9 recipe-description">
            <p>{recipe.description}</p>
          </div>
        </div>

        <div className="row">
          {/* Zutatenliste */}
          <div className="col-lg-3 mt-4 mx-auto text-lg-start text-center">
            <div className="subtitle text-center">Zutaten</div>
            {ingredientGroups.map((group) => (
              <div key={group.group_id} className="group-name mt-2">
                {group.group_name && <h5>{group.group_name}</h5>}
                <ul>
                  {ingredientsByGroup[group.group_id]?.map((ingredient, index) => (
                    <li key={index}>
                      <p>{ingredient.quantity} {ingredient.ingredient}</p>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Zubereitungsschritte */}
          <div className="col-lg-9 mt-4 detail-page">
            <div className="subtitle text-center">Zubereitung</div>
            <div className="steps-container">
              {recipeStepGroups.map((group) => (
                <div key={group.group_id} className="group-name mb-3">
                  {group.group_name && <h5 className="mb-4">{group.group_name}</h5>}
                  {stepsByGroup[group.group_id]?.map((step, index) => (
                    <div key={step.step_id} className="step-item mb-3">
                      <div className="d-flex align-items-center">
                        {stepsByGroup[group.group_id].length > 1 && <div className="step-number">{index + 1}</div>}
                        <div className={`ms-3 ${stepsByGroup[group.group_id].length > 1 ? '' : 'ms-0'}`}>
                          <p className="step-description">{step.description}</p>
                        </div>
                        {getTipsForStep(step.step_id).length > 0 && (
                          <div className="tip-icon ms-3">
                            <img src="../../assets/img/icon-img/idea.png" alt="Tip" onClick={() => toggleTipVisibility(step.step_id)} />
                          </div>
                        )}
                      </div>
                      {isTipVisible(step.step_id) && (
                        <div className="tip-content text-primary border-primary-subtle border bg-primary-subtle">
                          <button className="close-button" onClick={() => toggleTipVisibility(step.step_id)}>X</button>
                          {getTipsForStep(step.step_id).map((tip, index) => (
                            <div key={index} className="tip-text">{tip.tip_text}</div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
              <button className="btn btn-outline-primary mt-5 mb-5" onClick={downloadRecipeAsPDF}>Rezept als PDF herunterladen</button>
            </div>

            {/* Social Sharing */}
            <div className="social-sharing mt-4">
              <p className="mb-3">Teile dieses Rezept:</p>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" className="btn btn-outline-primary me-3 mb-3" rel="noreferrer">
                <i className="fa-brands fa-facebook"></i> Facebook
              </a>
              <a href={`https://twitter.com/intent/tweet?text=${recipe.name}&url=${window.location.href}`} target="_blank" className="btn btn-outline-info me-3 mb-3" rel="noreferrer">
                <i className="fa-brands fa-x"></i> Twitter
              </a>
              <a href={`https://pinterest.com/pin/create/button/?url=${window.location.href}&media=${recipe.name}&description=${recipe.description}`} target="_blank" className="btn btn-outline-danger me-3 mb-3" rel="noreferrer">
                <i className="fa-brands fa-pinterest"></i> Pinterest
              </a>
              <a href={`https://api.whatsapp.com/send?text=${recipe.name}%0A${window.location.href}`} target="_blank" className="btn btn-outline-success me-3 mb-3" rel="noreferrer">
                <i className="fa-brands fa-whatsapp"></i> WhatsApp
              </a>
              <button className="btn btn-outline-light me-3 mb-3" onClick={() => navigator.clipboard.writeText(window.location.href)}>
                <i className="fa-brands fa-instagram"></i> Instagram
              </button>
            </div>
          </div>
        </div>
        {/* Carousel */}
      <CarouselNotjamieoliver />
      </section>
    </div>
  );
};

export default RecipeDetailPageNotjamieoliver;

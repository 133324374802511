import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';

import TitleNotjamieoliver from './helper_components/title-notjamieoliver';
import NavbarNotjamieoliver from './helper_components/navbar-notjamieoliver'
import FooterNotjamieoliver from './helper_components/footer-notjamieoliver';
import MainPageNotjamieoliver from './view_components/main-page-notjamieoliver';
import DataProtectionPageNotjamieoliver from './view_components/data-protection-page-notjamieoliver';
import ImpressumPageNotjamieoliver from './view_components/impressum-page-notjamieoliver';
import AboutPageNotjamieoliver from './view_components/about-page-notjamieoliver';
import RecipeAllNotjamieoliver from './view_components/recipes-all-page-notjamieoliver';
import RecipeByTagNotjamieoliver from './view_components/recipes-by-tag-page-notjamieoliver';
import RecipeDetailPageNotjamieoliver from './view_components/recipe-detail-page-notjamieoliver';
import SearchResultsNotjamieoliver from './view_components/search-results-page-notjamieoliver';
import NewsletterUnsubscribeNotjamieoliver from './view_components/unsubscribe-email-page-notjamieoliver';
import VerifyEmailPageNotjamieoliver from './view_components/verify-email-page-notjamieoliver';

import { HelmetProvider } from 'react-helmet-async';
import { RecipesWithTagsProvider} from './helper_components/recipes-with-tags-context';
import { NavProvider } from './helper_components/navbar-notjamieoliver-context';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

const Root = () => {


  return (
    <BrowserRouter>
      <NavProvider>
      <RecipesWithTagsProvider>
      <HelmetProvider>
        <NavbarNotjamieoliver />
        <TitleNotjamieoliver />
        <Routes>
          <Route path="/" element={<MainPageNotjamieoliver />} />
          <Route path="/recipes-all-page-notjameioliver" element={<RecipeAllNotjamieoliver />} />
          <Route path="/recipes-by-tag-page-notjamieoliver/:tag_name" element={<RecipeByTagNotjamieoliver />} />
          <Route path="/recipe-detail-page-notjamieoliver/:id" element={<RecipeDetailPageNotjamieoliver />} />
          <Route path="/search-results-notjamieoliver" element={<SearchResultsNotjamieoliver />} />
          <Route path="/data-protection-page-notjamieoliver" element={<DataProtectionPageNotjamieoliver />} />
          <Route path="/impressum-page-notjamieoliver" element={<ImpressumPageNotjamieoliver />} />
          <Route path="/about-page-notjamieoliver" element={<AboutPageNotjamieoliver />} />
          <Route path="/unsubscribe-email-page-notjamieoliver" element={<NewsletterUnsubscribeNotjamieoliver />} />
          <Route path="/verify-email-notjamieoliver" element={<VerifyEmailPageNotjamieoliver />} />
          
        </Routes>
        <FooterNotjamieoliver />
      </HelmetProvider>
      </RecipesWithTagsProvider>
      </NavProvider>
    </BrowserRouter>
  );
};

// React root rendern
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);

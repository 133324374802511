import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { verifyEmail } from '../helper_components/connection-notjamieoliver'; // Importiere die Funktion aus der connection component
import { useNavContext } from '../helper_components/navbar-notjamieoliver-context'; // Importiere den NavContext

const VerifyEmailPageNotjamieoliver = () => {
  const [message, setMessage] = useState('');
  const location = useLocation();
  const { setActivePage } = useNavContext();

  useEffect(() => {
    // Setze den aktiven Menüpunkt auf 'verify-email', wenn die Seite geladen wird
    setActivePage('verify-email');
  }, [setActivePage]);

  useEffect(() => {
    // Extrahiere den Token aus der URL
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      // API-Aufruf zur Verifizierung der E-Mail-Adresse
      verifyEmail(token)
        .then((response) => {
          setMessage(response.message);
        })
        .catch(() => {
          setMessage('Verifizierung fehlgeschlagen. Ungültiger oder abgelaufener Token.');
        });
    } else {
      setMessage('Kein Verifizierungstoken gefunden.');
    }
  }, [location.search]);

    return (
        <section className="container d-flex justify-content-center align-items-center vh-100">
            <div className="section_padding_100 login-container">
            <div className="verify-email-container">
                <h2>Email Verifizierung</h2>
                <p>{message}</p>
            </div>
            </div>
        </section>
        );

};

export default VerifyEmailPageNotjamieoliver;

import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RecipesWithTagsContext } from '../helper_components/recipes-with-tags-context';
import { useNavContext } from '../helper_components/navbar-notjamieoliver-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';

const RecipeAllNotjamieoliver = () => {
    const { recipesWithTags } = useContext(RecipesWithTagsContext);  // Korrekte Destrukturierung
    const [pagedRecipes, setPagedRecipes] = useState([]);
    const itemsPerPage = 4;
    const pagesPerSet = 3;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPageSet, setCurrentPageSet] = useState(1);
    const [loading, setLoading] = useState(true);
    const { setActivePage } = useNavContext();  // Zugriff auf den Setter für den aktiven Zustand

    useEffect(() => {
        setActivePage('recipe');  // Setze die aktive Seite auf 'recipe', wenn die Seite geladen wird
    }, [setActivePage]);

    useEffect(() => {
        if (recipesWithTags && recipesWithTags.length > 0) {
            const calculatedTotalPages = Math.ceil(recipesWithTags.length / itemsPerPage);
            setTotalPages(calculatedTotalPages);
            setCurrentPageSet(Math.ceil(currentPage / pagesPerSet)); // Update page set when current page changes
            updatePagedRecipes(); // Direkt nach Änderung von recipesWithTags und currentPage
            setLoading(false);
        }
    }, [recipesWithTags, currentPage]);

    const updatePagedRecipes = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setPagedRecipes(recipesWithTags.slice(startIndex, endIndex));
    };

    const getPageRange = () => {
        const startPage = (currentPageSet - 1) * pagesPerSet + 1;
        const endPage = Math.min(startPage + pagesPerSet - 1, totalPages);
        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    const nextPageSet = () => {
      if (currentPageSet * pagesPerSet < totalPages) {
        const nextPageSetValue = currentPageSet++;
        setCurrentPageSet(nextPageSetValue);  

        // Optionally change the currentPage to the first page of the new set
        const currentPageSetValue = (currentPageSet - 1) * pagesPerSet + 1;
        setCurrentPage(currentPageSetValue);
        updatePagedRecipes();
      }
    };
  
    const previousPageSet = () => {
        if (currentPageSet > 1) {
            const previousPageSetValue = currentPageSet - 1;
            setCurrentPageSet(previousPageSetValue);
            const currentPageSetValue = (previousPageSetValue) * pagesPerSet;
            setCurrentPage(currentPageSetValue);
            updatePagedRecipes();
          }
    };

    const changePage = (page) => {
      setCurrentPage(page);
      updatePagedRecipes();
  };
  
  const nextPage = () => {
      if (currentPage < totalPages) {
          const currentPageSetValue = currentPage + 1;  // Increment the page
          setCurrentPage(currentPageSetValue);
          updatePagedRecipes();  // Call the function to update the displayed recipes
      }
  };
  
  const previousPage = () => {
      if (currentPage > 1) {
          const currentPageSetValue = currentPage - 1;  // Decrement the page
          setCurrentPage(currentPageSetValue);
          updatePagedRecipes();  // Call the function to update the displayed recipes
      }
  };
  
  if (loading) {
      return <div>Daten werden geladen...</div>;
  }
  
    return (
        <div className="container">
            <h1 className="mt-3 mb-3 text-center">Alle Rezepte</h1>

            <div className="row">
                {pagedRecipes.map((recipesWithTags) => (
                    <div className="col-12" key={recipesWithTags.id}>
                        <div
                            className="list-blog single-post d-sm-flex wow fadeInUpBig"
                            data-wow-delay=".2s"
                            style={{ visibility: 'visible' }}
                        >
                            {/* Post Thumb */}
                            <div className="mb-3 post-thumb flex-shrink-0 col-lg-6 col-md-6 col-sm-12">
                                <Link to={`/recipe-detail-page-notjamieoliver/${recipesWithTags.id}`}>
                                    <img
                                        src={`https://images.notjamieoliver.ch/assets/img/recipe-img/${recipesWithTags.id}/${recipesWithTags.id}_md.jpg`}
                                        alt={recipesWithTags.name}
                                        className="img-fluid"
                                    />
                                </Link>
                            </div>

                            {/* Post Content */}
                            <div className="post-content col-lg-6 col-md-6 col-sm-12">
                                <div className="post-meta d-flex">
                                    <div className="post-author-date-area d-flex">
                                        {/* Post Author */}
                                        <div className="post-author me-2">Von {recipesWithTags.author}</div>
                                        {/* Post Date */}
                                        <div className="post-date">{recipesWithTags.publishing_date_str}</div>
                                    </div>
                                    {/* Post Comment & Share Area */}
                                    <div className="post-comment-share-area d-flex">
                                        <div className="post-view">
                                            {recipesWithTags.views}
                                            <i className="ms-3"><FontAwesomeIcon icon={faEye} /></i>
                                        </div>
                                    </div>
                                </div>

                                <Link to={`/recipe-detail-page-notjamieoliver/${recipesWithTags.id}`}>
                                    <h4 className="post-headline">{recipesWithTags.name}</h4>
                                </Link>
                                <p>{recipesWithTags.description}</p>

                                <div className="mt-3">
                                    {recipesWithTags.tags.map((tag, index) => (
                                        <span key={index} className="badge text-primary border-primary-subtle border bg-primary-subtle me-1">
                                            <Link to={`/recipes-by-tag-page-notjamieoliver/${tag}`}>{tag}</Link>
                                        </span>
                                    ))}
                                </div>

                                <div>
                                    <Link to={`/recipe-detail-page-notjamieoliver/${recipesWithTags.id}`}>
                                        <button className="btn btn-outline-primary mt-3">Weiter...</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Pagination Controls */}
            <div className="pagination-controls mt-4 text-center">
                {/* Previous Button */}
                <button 
                  className="btn btn-outline-primary me-2 mb-5" 
                  onClick={previousPageSet} 
                  disabled={currentPageSet === 1}
                >
                    {'<<'}
                </button>

                {/* Pages Display */}
                {getPageRange().map((page) => (
                    <button
                        key={page}
                        className={`btn btn-outline-light me-2 mb-5 ${page === currentPage ? 'active' : ''}`}
                        onClick={() => changePage(page)}
                    >
                        {page}
                    </button>
                ))}

                {/* Next Button */}
                <button
                    className="btn btn-outline-primary mb-5"
                    onClick={nextPageSet}
                    disabled={currentPageSet * pagesPerSet >= totalPages}
                >
                    {'>>'}
                </button>
            </div>
        </div>
    );
};

export default RecipeAllNotjamieoliver;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import { RecipesWithTagsContext } from './recipes-with-tags-context';  // Benannter Export importieren
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';  // Verwende Solid-Icons für Pfeile
import '../assets/css/others/owl.carousel.css';
import '../assets/css/others/owl.theme.default.css';
import '../assets/css/others/styles.css';  // Diese Datei wird zuletzt importiert

const CarouselNotjamieoliver = () => {
    const { recipesWithTags } = useContext(RecipesWithTagsContext);  // Korrekte Destrukturierung
    const [shuffledRecipes, setShuffledRecipes] = useState([]);
    const [currentUrl, setCurrentUrl] = useState(window.location.href); // Hier wird die aktuelle URL gesetzt
    const carouselRef = useRef(null); // carouselRef mit useRef initialisieren


    useEffect(() => {
        shuffleRandomRecipes(recipesWithTags);
    }, [recipesWithTags]);  // useEffect wird ausgelöst, wenn sich die Rezepte ändern

    const shuffleRandomRecipes = (recipes) => {
        let shuffled = [...recipes];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        setShuffledRecipes(shuffled);
    };


    const customOptionsWelcome = {
        loop: true,
        items: 4,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplaySpeed: 1000,
        dotsSpeed: 500,
        rewind: false,
        dots: false,
        smartSpeed: 400,
        dragEndSpeed: 350,
        startPosition: 2,
        slideBy: 'page',

        responsive: {
            0: {
                items: 2,
            },
            600: {
                items: 3,
            },
            900: {
                items: 4,
            },
        },
        nav: false,
    };

    return (
        <div className="container pt-4">

            <div className="welcome-single-slide-line-top mt-5"></div>

            {shuffledRecipes.length > 0 && (
            <div className="carousel-container">
              <OwlCarousel ref={carouselRef} id="portfolio" {...customOptionsWelcome}>
                {shuffledRecipes.map((recipe, index) => (
                  <div className="recipe-slide" key={index}>
                    <div className="recipe-container">
                      <div className="recipe-image">
                        <Link to={`/recipe-detail-page-notjamieoliver/${recipe.id}`}>
                          <img
                            src={`https://images.notjamieoliver.ch/assets/img/recipe-img/${recipe.id}/${recipe.id}_th.jpg`}
                            alt={recipe.name}
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                      <div className="recipe-info">
                        <p>
                          <Link to={`/recipe-detail-page-notjamieoliver/${recipe.id}`} className="recipe-date">
                            {new Date(recipe.publishing_date_str).toLocaleDateString('de-DE', {
                              day: 'numeric',
                              month: 'long',
                              year: 'numeric',
                            })}
                          </Link>
                        </p>
                        <p>
                          <Link to={`/recipe-detail-page-notjamieoliver/${recipe.id}`} className="recipe-title">
                            {recipe.name}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>

              <button className="btn btn-outline-primary button-prev" onClick={() => carouselRef.current.prev()}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <button className="btn btn-outline-primary button-next" onClick={() => carouselRef.current.next()}>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          )}


            <div className="welcome-single-slide-line-bottom mt-5"></div>
        </div>
    );
};

export default CarouselNotjamieoliver;

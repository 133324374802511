import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useNavContext } from '../helper_components/navbar-notjamieoliver-context';  // Den Kontext importieren

// Mock AuthService and CommunicationService (replace with actual implementations)
const useAuthService = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMaster, setIsMaster] = useState(false);

  const getLoginStatus = () => isLoggedIn;
  const checkIfMaster = () => isMaster;

  const login = () => setIsLoggedIn(true);
  const logout = () => setIsLoggedIn(false);

  return { getLoginStatus, checkIfMaster, login, logout, isMaster };
};

const NavbarNotjamieoliver = () => {
  const { activePage } = useNavContext(); // Nutze den Context, um die aktive Seite zu bekommen
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMaster, setIsMaster] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentUrl, setCurrentUrl] = useState(window.location.pathname); // Für aktive Route
  const authService = useAuthService();
  const navigate = useNavigate();

  useEffect(() => {
    // Setze den Login-Status und Master-Status
    setIsLoggedIn(authService.getLoginStatus());
    setIsMaster(authService.checkIfMaster());

    // URL-Änderungen überwachen
    const handleUrlChange = () => setCurrentUrl(window.location.pathname);
    window.addEventListener('popstate', handleUrlChange);

    return () => window.removeEventListener('popstate', handleUrlChange);
  }, []);

  // Suchlogik
  const performSearch = () => {
    if (searchQuery) {
      navigate(`/search-results-notjamieoliver?query=${searchQuery}`);
    }
  };

  // Menü schließen (für Mobilansicht)
  const closeMenu = () => {
    const navbar = document.getElementById('navbarTogglerDemo03');
    if (navbar && navbar.classList.contains('show')) {
      navbar.classList.remove('show'); // Menü schließen
    }
  };

  // Logout-Handler
  const logout = () => {
    authService.logout();
    setIsLoggedIn(false);
    setIsMaster(false);
    navigate('/main-page-notjamieoliver');
  };

  return (
    <header className="header_area bg-black sticky-top">
      <div className="container">
        <nav className="navbar navbar-expand-sm bg-black" data-bs-theme="dark">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo03"
              aria-controls="navbarTogglerDemo03"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            {/* Navbar Brand (Logo) */}
            <Link className="navbar-brand" to="/">
              <img
                src="/assets/img/logo-img/logo-hohe-80px-toffol-cooking-blau.png"
                id="navbar-logo"
                alt="Logo"
                className="d-inline-block align-text-center"
              />
            </Link>

            {/* Navbar-Links */}
            <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className={`nav-link ${activePage === 'home' ? 'nav-link-active' : ''}`}  // Verwende den aktiven Zustand
                    to="/"
                    onClick={closeMenu}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${activePage === 'recipe' ? 'nav-link-active' : ''}`}  // Verwende den aktiven Zustand
                    to="/recipes-all-page-notjameioliver"
                    onClick={closeMenu}
                  >
                    Rezepte
                  </Link>
                </li>
              </ul>

              {/* Suche und Auth-Buttons */}
              <div className="d-flex ms-auto" role="search">
                <input
                  className="form-control me-2"
                  type="search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search"
                  aria-label="Search"
                  onKeyUp={(e) => e.key === 'Enter' && performSearch()}
                />
                <button className="btn btn-outline-primary" onClick={performSearch}>
                  Search
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default NavbarNotjamieoliver;

import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { searchRecipes } from '../helper_components/connection-notjamieoliver'; // Funktion aus der connection component
import { useNavContext } from '../helper_components/navbar-notjamieoliver-context';

const SearchResultsNotjamieoliver = () => {
  const [pagedRecipes, setPagedRecipes] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [itemsPerPage] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Korrigiert, beginnt bei 0
  const [pagesPerSet] = useState(3);
  const [currentPageSet, setCurrentPageSet] = useState(1);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const { setActivePage } = useNavContext();  // Zugriff auf den NavContext

  useEffect(() => {
    setActivePage('search-result');  // Setzt den aktiven Menüpunkt
  }, [setActivePage]);

  const updatePagedRecipes = useCallback((recipes) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPagedRecipes(recipes.slice(startIndex, endIndex));
  }, [currentPage, itemsPerPage]);

  // On Component Mount
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('query') || '';
    setSearchQuery(query);

    if (query) {
      setLoading(true);
      // Rezepte abrufen und setzen
      searchRecipes(query).then((recipesData) => {
        setSearchResults(recipesData || []);
        setTotalPages(Math.ceil(recipesData.length / itemsPerPage));  // totalPages setzen
        updatePagedRecipes(recipesData);  // Verwende die tatsächlichen Daten
        setLoading(false);
      }).catch((error) => {
        console.error('Fehler beim Abrufen der Suchergebnisse:', error);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [location.search, itemsPerPage, updatePagedRecipes]);

  const getPageRange = () => {
    const startPage = (currentPageSet - 1) * pagesPerSet + 1;
    const endPage = Math.min(startPage + pagesPerSet - 1, totalPages);
    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };

  const changePage = (page) => {
    setCurrentPage(page);
    updatePagedRecipes(searchResults);
  };

  const nextPageSet = () => {
    if (currentPageSet * pagesPerSet < totalPages) {
      setCurrentPageSet(currentPageSet + 1);
      setCurrentPage((currentPageSet) * pagesPerSet + 1);
      updatePagedRecipes(searchResults);
    }
  };

  const previousPageSet = () => {
    if (currentPageSet > 1) {
      setCurrentPageSet(currentPageSet - 1);
      setCurrentPage(currentPageSet * pagesPerSet);
      updatePagedRecipes(searchResults);
    }
  };

  if (loading) {
    return <div>Daten werden geladen...</div>;
  }

  return (
    <div className="container">
      <h1 className="mt-3 mb-3 text-center">Rezepte nach Tag "{searchQuery}"</h1>

      <div className="row">
        {pagedRecipes.map((recipe) => (
          <div className="col-12" key={recipe.id}>
            <div
              className="list-blog single-post d-sm-flex wow fadeInUpBig"
              data-wow-delay=".2s"
              style={{ visibility: 'visible' }}
            >
              {/* Post Thumb */}
              <div className="mb-3 post-thumb flex-shrink-0 col-lg-6 col-md-6 col-sm-12">
                <a href={`/detail-page-notjamieoliver/${recipe.id}`}>
                  <img
                    src={`https://images.notjamieoliver.ch/assets/img/recipe-img/${recipe.id}/${recipe.id}_md.jpg`}
                    alt={recipe.name}
                    className="img-fluid"
                  />
                </a>
              </div>
              {/* Post Content */}
              <div className="post-content col-lg-6 col-md-6 col-sm-12">
                <div className="post-meta d-flex">
                  <div className="post-author-date-area d-flex">
                    {/* Post Author */}
                    <div className="post-author me-2">Von {recipe.author}</div>
                    {/* Post Date */}
                    <div className="post-date">
                      {new Date(recipe.publishing_date_str).toLocaleDateString('de-DE', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })}
                    </div>
                  </div>
                  {/* Post Comment & Share Area */}
                  <div className="post-comment-share-area d-flex">
                    <div className="post-view">
                      {recipe.views}
                      <i className="ms-3"><FontAwesomeIcon icon={faEye} /></i>
                    </div>
                  </div>
                </div>
                <a href={`/detail-page-notjamieoliver/${recipe.id}`}>
                  <h4 className="post-headline">{recipe.name}</h4>
                </a>
                <p>{recipe.description}</p>
                <div className="mt-3">
                  {recipe.tags.map((tag) => (
                    <span key={tag} className="badge text-primary border-primary-subtle border bg-primary-subtle me-1">
                      <a href={`/recipes-by-tag-page-notjamieoliver/${tag}`}>{tag}</a>
                    </span>
                  ))}
                </div>
                <div>
                  <a href={`/detail-page-notjamieoliver/${recipe.id}`}>
                    <button className="btn btn-outline-primary mt-3">Weiter...</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls mt-4 text-center">
        {/* Previous Button */}
        <button className="btn btn-outline-primary me-2 mb-5" onClick={previousPageSet} disabled={currentPageSet === 1}>
          &lt;&lt;
        </button>

        {/* Pages Display */}
        {getPageRange().map((page) => (
          <button
            key={page}
            className={`btn btn-outline-light me-2 mb-5 ${page === currentPage ? 'active' : ''}`}
            onClick={() => changePage(page)}
          >
            {page}
          </button>
        ))}

        {/* Next Button */}
        <button className="btn btn-outline-primary mb-5" onClick={nextPageSet} disabled={currentPageSet * pagesPerSet >= totalPages}>
          &gt;&gt;
        </button>
      </div>
    </div>
  );
};

export default SearchResultsNotjamieoliver;

import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel'; 
import '../assets/css/others/owl.carousel.css';
import '../assets/css/others/owl.theme.default.css';
import '../assets/css/others/styles.css';  //  



const TitleNotjamieoliver = () => {
  const customOptionsTitle = {
    loop: true,
    margin: 10,
    nav: false,
    dots: true,
    items: 1,
    autoplay: true,
    autoplayTimeout: 5000,
  };

  return (
    <section className="bg-img" style={{ backgroundImage: "url('../assets/img/bg-img/img_bg_header.jpg')" }}>
      <div className="container container_title">
        <div className="row align-items-center" style={{ height: '100vh' }}>
          {/* Progress bar container */}
          <div className="col-lg-8 col-md-10 col-sm-12">
            <div className="progress-bar-container">
              <div className="progress-bar-fill"></div>
            </div>

            {/* Owl Carousel */}
            <OwlCarousel autoplay={true} items={1} loop={true} margin={10} nav={false} dots={ true} autoplayTimeout={5000} className="owl-carousel owl-theme">
              <div className="title_slideshow_item">
                {/* Slideshow Content */}
                <h2 className="slide-title">Herzlich Willkommen</h2>
                <h3 className="slide-subtitle">Meine Rezepte</h3>
                <p className="slide-description">
                  Ein Auszug aus meinem Hobby-Koch-Leben und aus meinem Kochstil.
                </p>
                <Link to="/recipes-all-page-notjameioliver" className="btn btn-outline-primary btn-lg">Meine Rezepte</Link>
              </div>

              <div className="title_slideshow_item">
                <h2 className="slide-title">Herzlich Willkommen</h2>
                <h3 className="slide-subtitle">Meine Ideen</h3>
                <p className="slide-description">
                  Viele Rezepte entstanden durch die Inspiration von Jamie Oliver, aber nicht nur...
                </p>
                <Link to="/recipes-all-page-notjameioliver" className="btn btn-outline-primary btn-lg">Meine Ideen</Link>
              </div>

              <div className="title_slideshow_item">
                <h2 className="slide-title">Herzlich Willkommen</h2>
                <h3 className="slide-subtitle">Meine Tips</h3>
                <p className="slide-description">
                  Nützliche Tipps sind überall in meinen Rezepten zu finden, bitte bedient euch.
                </p>
                <Link to="/recipes-all-page-notjameioliver" className="btn btn-outline-primary btn-lg">Meine Tipps</Link>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TitleNotjamieoliver;

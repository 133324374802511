import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { RecipesWithTagsContext } from '../helper_components/recipes-with-tags-context';
import { useNavContext } from '../helper_components/navbar-notjamieoliver-context';

const RecipeByTagNotjamieoliver = () => {
  const { tag_name } = useParams();  // Holt den Tag-Namen aus der URL
  const { recipesWithTags } = useContext(RecipesWithTagsContext);  // Holt die Rezepte aus dem Context
  const [pagedRecipes, setPagedRecipes] = useState([]);
  const itemsPerPage = 4;
  const pagesPerSet = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageSet, setCurrentPageSet] = useState(1);
  const [loading, setLoading] = useState(true);
  const { setActivePage } = useNavContext();  // Zugriff auf den NavContext

  useEffect(() => {
    setActivePage('recipes-by-tag');  // Setzt den aktiven Menüpunkt
  }, [setActivePage]);

  useEffect(() => {
    if (recipesWithTags && recipesWithTags.length > 0) {
      const filteredRecipes = recipesWithTags.filter(recipe =>
        recipe.tags.includes(tag_name)  // Filtert die Rezepte nach dem Tag
      );
      setTotalPages(Math.ceil(filteredRecipes.length / itemsPerPage));
      updatePagedRecipes(filteredRecipes);
      setLoading(false);
    }
  }, [recipesWithTags, tag_name, currentPage]);

  const updatePagedRecipes = (filteredRecipes) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPagedRecipes(filteredRecipes.slice(startIndex, endIndex));
  };

  const getPageRange = () => {
    const startPage = (currentPageSet - 1) * pagesPerSet + 1;
    const endPage = Math.min(startPage + pagesPerSet - 1, totalPages);
    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };

  const nextPageSet = () => {
    if (currentPageSet * pagesPerSet < totalPages) {
      setCurrentPageSet(currentPageSet + 1);  // Erhöht die aktuelle Seitengruppe
      setCurrentPage((currentPageSet) * pagesPerSet + 1);
    }
  };

  const previousPageSet = () => {
    if (currentPageSet > 1) {
      setCurrentPageSet(currentPageSet - 1);  // Verringert die aktuelle Seitengruppe
      setCurrentPage((currentPageSet - 1) * pagesPerSet);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return <div>Daten werden geladen...</div>;
  }

  return (
    <div className="container">
      <h1 className="mt-3 mb-3 text-center">Rezepte nach Tag "{tag_name}"</h1>

      <div className="row">
        {pagedRecipes.map((recipe) => (
          <div className="col-12" key={recipe.id}>
            <div
              className="list-blog single-post d-sm-flex wow fadeInUpBig"
              data-wow-delay=".2s"
              style={{ visibility: 'visible' }}
            >
              {/* Post Thumb */}
              <div className="mb-3 post-thumb flex-shrink-0 col-lg-6 col-md-6 col-sm-12">
                <Link to={`/recipe-detail-page-notjamieoliver/${recipe.id}`}>
                  <img
                    src={`https://images.notjamieoliver.ch/assets/img/recipe-img/${recipe.id}/${recipe.id}_md.jpg`}
                    alt={recipe.name}
                    className="img-fluid"
                  />
                </Link>
              </div>

              {/* Post Content */}
              <div className="post-content col-lg-6 col-md-6 col-sm-12">
                <div className="post-meta d-flex">
                  <div className="post-author-date-area d-flex">
                    {/* Post Author */}
                    <div className="post-author me-2">Von {recipe.author}</div>
                    {/* Post Date */}
                    <div className="post-date">{recipe.publishing_date_str}</div>
                  </div>
                  {/* Post Comment & Share Area */}
                  <div className="post-comment-share-area d-flex">
                    <div className="post-view">
                      {recipe.views}
                      <i className="ms-3"><FontAwesomeIcon icon={faEye} /></i>
                    </div>
                  </div>
                </div>

                <Link to={`/recipe-detail-page-notjamieoliver/${recipe.id}`}>
                  <h4 className="post-headline">{recipe.name}</h4>
                </Link>
                <p>{recipe.description}</p>

                <div className="mt-3">
                  {recipe.tags.map((tag, index) => (
                    <span key={index} className="badge text-primary border-primary-subtle border bg-primary-subtle me-1">
                      <Link to={`/recipes-by-tag-page-notjamieoliver/${tag}`}>{tag}</Link>
                    </span>
                  ))}
                </div>

                <div>
                  <Link to={`/recipe-detail-page-notjamieoliver/${recipe.id}`}>
                    <button className="btn btn-outline-primary mt-3">Weiter...</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls mt-4 text-center">
        {/* Previous Button */}
        <button className="btn btn-outline-primary me-2 mb-5" onClick={previousPage} disabled={currentPageSet === 0}>
          {'<<'}
        </button>

        {/* Pages Display */}
        {getPageRange().map((page) => (
          <button
            key={page}
            className={`btn btn-outline-light me-2 mb-5 ${page === currentPage ? 'active' : ''}`}
            onClick={() => changePage(page)}
          >
            {page}
          </button>
        ))}

        {/* Next Button */}
        <button
          className="btn btn-outline-primary mb-5"
          onClick={nextPage}
          disabled={currentPageSet * pagesPerSet >= totalPages}
        >
          {'>>'}
        </button>
      </div>
    </div>
  );
};

export default RecipeByTagNotjamieoliver;

import React, { useEffect } from 'react';
import { useNavContext } from '../helper_components/navbar-notjamieoliver-context';

const AboutPageNotjamieoliver = () => {
    const { setActivePage } = useNavContext();  // Zugriff auf den Setter für den aktiven Zustand

    useEffect(() => {
        setActivePage('about');  // Setze die aktive Seite auf 'about', wenn die Seite geladen wird
    }, [setActivePage]);

  return (
    <div className="container about-container mt-5 mb-5">
      {/* Abschnitt 1 */}
      <div className="row mb-5">
        <div className="col-lg-8 col-md-8 col-sm-12 mb-4">
          <h2>Über mich</h2>
          <p>
            Ich heisse Oliver, aber Jamie Oliver bin ich nicht – dennoch inspiriert er mich bei meinen Rezepten. Seine Einfachheit und sein Stil spiegeln sich auch in meiner Küche wider.
          </p>
          <p>
            Die Leidenschaft fürs Kochen wurde mir quasi in die Wiege gelegt, wahrscheinlich von meinem Vater geerbt.
          </p>
          <p>
            Obwohl ich nur privat koche, bereite ich mit Freude Gerichte zu, die nicht nur mir, sondern auch meinen Gästen ein Lächeln ins Gesicht zaubern.
          </p>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 order-md-last order-first">
          <img src="/assets/img/about-img/about_001.jpg" alt="Über mich" className="img-fluid" />
        </div>
      </div>

      {/* Abschnitt 2 */}
      <div className="row mb-5">
        <div className="col-lg-4 col-md-4 col-sm-12 order-md-first order-last">
          <img src="/assets/img/about-img/about_002.jpg" alt="Über mich" className="img-fluid" />
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 mb-4">
          <h2>Warum notjamieoliver?</h2>
          <p>
            Mit dem Vornamen Oliver und einer tiefen Leidenschaft fürs Kochen, wollte ich Jamie Oliver auf meine Weise Tribut zollen. Er ist für mich eine klare Inspirationsquelle.
          </p>
          <p>
            Aber ich bin eben nicht Jamie Oliver, und deshalb heisst diese Seite 'notjamieoliver'.
          </p>
          <p>
            Ich schätze seine Rezepte und die Art, wie er aus einfachen Zutaten grossartige Gerichte kreiert. Seine Zubereitungsmethoden sind unkompliziert und passen perfekt zu meiner Philosophie: So viel wie möglich mit den Händen arbeiten.
          </p>
        </div>
      </div>

      {/* Abschnitt 3 */}
      <div className="row mb-5">
        <div className="col-lg-8 col-md-8 col-sm-12 mb-4">
          <h2>Meine Vision</h2>
          <p>
            Ich plane, hier so viele Rezepte wie möglich zu veröffentlichen. Es soll eine wachsende Sammlung aus köstlichen Menüs, hilfreichen Tipps und praktischen Hinweisen entstehen.
          </p>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 order-md-last order-first">
          <img src="/assets/img/about-img/about_003.jpg" alt="Über mich" className="img-fluid" />
        </div>
      </div>

      {/* Abschnitt 4 */}
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12 order-md-first order-last">
          <img src="/assets/img/about-img/about_004.jpg" alt="Über mich" className="img-fluid" />
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 mb-4">
          <h2>Meine Arbeitsweise</h2>
          <p>
            Aus zahlreichen Rezeptideen entstehen bei mir immer neue Kreationen – ganz nach meinem Geschmack, und vielleicht auch nach dem anderer.
          </p>
          <p>
            Ich lasse meiner Fantasie freien Lauf, füge hinzu, was mir gefällt, und lasse weg, was mir weniger zusagt. Dabei entdecke ich immer wieder neue Kombinationen und Variationen.
          </p>
          <p>
            Zudem arbeite ich gerne mit den Händen und einem grossen, scharfen Messer.
          </p>
        </div>
      </div>

      {/* Inline styles for responsive order classes */}
      <style>
        {`
          @media (max-width: 768px) {
            .order-md-first {
              order: 2 !important;
            }
            .order-md-last {
              order: 1 !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default AboutPageNotjamieoliver;

import React, { useEffect } from 'react';
import { useNavContext } from '../helper_components/navbar-notjamieoliver-context';

const ImpressumPageNotjamieoliver = () => {
  const { setActivePage } = useNavContext();  // Zugriff auf den Setter für den aktiven Zustand

  useEffect(() => {
    setActivePage('impressum');  // Setze die aktive Seite auf 'impressum', wenn die Seite geladen wird
  }, [setActivePage]);

  return (
    <div className="container mt-5 mb-5">
      <h1 className="mb-4">Impressum</h1>

      <h2 className="mt-3">Kontakt</h2>
      <p>
        E-Mail: <a href="mailto:info@notjamieoliver.ch">info&#64;notjamieoliver.ch</a>
      </p>

      <h2 className="mt-4">Haftungsausschluss</h2>

      <h3 className="mt-3">Haftung für Inhalte</h3>
      <p>
        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
      </p>

      <h3 className="mt-3">Haftung für Links</h3>
      <p>
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. 
        Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
      </p>

      <h3 className="mt-3">Haftung für Betrieb</h3>
      <p>
        Wir halten uns das Recht vor, sämtliche Angebote und die Funktionen auf der Plattform ohne vorherige Ankündigung weiterzuentwickeln, zu erweitern, zu ändern oder zu entfernen oder den Betrieb der Internetseite ganz einzustellen. 
        Daraus können gegenüber uns keine Ansprüche, insbesondere solche auf Schadenersatz, geltend gemacht werden.<br />

        Die Besucher*innen verpflichten sich, den ordnungsgemäßen Betrieb der Internetseite nicht zu gefährden, insbesondere durch das Unterlassen von übermäßiger Belastung der Infrastruktur.    
      </p>

      <h2 className="mt-4">Urheberrecht</h2>
      <p>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem Urheberrecht. 
        Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
        Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
      </p>

      <h2 className="mt-4">Nutzung auf eigene Gefahr</h2>
      <p>
        Die Nutzung der auf unserer Webseite zur Verfügung gestellten Rezepte erfolgt auf eigene Gefahr. Sie garantieren weder die Korrektheit noch die sichere Anwendung.
        Wir übernehmen keine Haftung für etwaige Schäden, die durch unsachgemäße Zubereitung oder fehlerhafte Angaben in den Rezepten entstehen könnten. 
        Bitte achten Sie stets auf die korrekte Verarbeitung der Zutaten und befolgen Sie die angegebenen Anweisungen sorgfältig.
      </p>
    </div>
  );
};

export default ImpressumPageNotjamieoliver;
